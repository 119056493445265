<template>
  <!--
    Used to display the configuration(s) of the relevant device.
  -->
  <Portlet
    :title="$t('configuration')"
    icon="cog"
    class="deviceDetailConfiguration"
  >
    <template
      v-if="!authenticationHasRole('firstlevel') && !authenticationHasRole('zerolevel') && configuration && unrolledConfiguration != null"
      slot="buttons"
    >
      <button
        class="btn btn-secondary btn-sm mr-2"
        @click="openConfigurationsModal()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="edit"
        />
        <span>{{ $t('manageConfigurations') }}</span>
      </button>
      <button
        class="btn btn-secondary btn-sm"
        @click="toggleConfiguration()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="edit"
        />
        <span>{{ $t('editCurrent') }}</span>
      </button>
    </template>

    <LoadingPlaceholder v-if="loading" />

    <template v-else-if="editConfigurationBoolean">
      <DeviceDetailConfigurationEdit
        v-if="configuration"
        :configuration="configuration"
        :uuid="uuid"
        :restart-app-command-available="restartAppCommandAvailable"
        :restart-app-command="restartAppCommand"
        :install-command-available="restartAppCommandAvailable"
        :install-command="installCommand"
        :configuration-id="configurationId"
        :type="device.applicationType"
        @toggleConfiguration="toggleConfiguration()"
        @getConfiguration="getConfig(false)"
      />
    </template>

    <template v-else>
      <template v-if="configuration && unrolledConfiguration != null">
        <ul
          class="nav nav-tabs"
          role="tablist"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              data-toggle="tab"
              href="#conf"
              role="tab"
              aria-controls="conf"
              aria-selected="true"
            >{{ $t('configuration') }}</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              data-toggle="tab"
              href="#unConf"
              role="tab"
              aria-controls="unConf"
              aria-selected="false"
            >{{ $t('deviceDetailConfigurationComponent.unrolledConfiguration') }}</a>
          </li>
        </ul>
        <div class="tab-content">
          <div
            v-if="configuration"
            id="conf"
            class="tab-pane fade show active"
            role="tabpanel"
            aria-labelledby="conf-tab"
          >
            <DeviceDetailSmConfiguration
              v-if="isSmConfig"
              :configuration="configuration"
            />
            <DeviceDetailQtConfiguration
              v-else-if="isQtConfig"
              :configuration="configuration"
            />
            <DeviceDetailStandardConfiguration
              v-else
              :configuration="configuration"
            />
          </div>
          <div
            v-if="unrolledConfiguration != null"
            id="unConf"
            class="tab-pane fade"
            role="tabpanel"
            aria-labelledby="unConf-tab"
          >
            <DeviceDetailQtConfiguration
              v-if="isQtConfig"
              :configuration="unrolledConfiguration"
            />
            <DeviceDetailUnrolledConfiguration
              v-else
              :unrolled-configuration="unrolledConfiguration"
            />
          </div>
        </div>
      </template>
      <p v-else>
        {{ $t('noDataAvailable') }}
      </p>
    </template>
    <SweetModal
      ref="configurationsModal"
      blocking
      :title="$t('manageConfigurations')"
      @close="closeModal()"
    >
      <div class="form-group">
        <label>{{ $t('configuration') }}</label>
        <LoadingPlaceholder v-if="configurationsLoading" />
        <template v-else>
          <Multiselect
            id="selectConf"
            v-model="selectedConfiguration"
            placeholder="Select a configuration"
            :options="configurations"
            select-label=""
            :multiple="false"
            :taggable="false"
            :searchable="true"
            :close-on-select="true"
            :custom-label="customLabel"
            track-by="id"
            class="mb-4"
          />
          <LoadingPlaceholder v-if="singleConfigurationsLoading" />
          <template v-else>
            <hr class="m-0 mb-3">
            <label>{{ $t('name') }}</label>
            <input
              v-model="configurationForEdit.name"
              v-focus
              class="form-control"
              type="text"
            >
            <hr class="m-0 mb-3">
            <label>{{ $t('deviceDetailConfigurationEditComponent.applicationUrl') }}</label>
            <input
              v-model="configurationForEdit.applicationUrl"
              class="form-control"
              type="text"
            >
            <hr class="m-0 mb-3">
            <label>{{ $t('deviceDetailConfigurationEditComponent.configurationData') }}</label>
            <textarea
              id="conf"
              v-model="configurationForEdit.configuration"
              rows="7"
              class="form-control"
            />
            <hr class="m-0 mb-3">
            <label>Set as active configuration</label>
            <input
              v-model="configurationForEdit.setAsActive"
              type="checkbox"
              class="ml-3"
            >
          </template>
        </template>
        <div class="clearfix" />
      </div>
      <button
        slot="button"
        class="btn btn-primary float-left mr-3"
        @click="resetSelectedConfiguration()"
      >
        {{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-primary float-right mr-3"
        :disabled="!(selectedConfiguration && selectedConfiguration.id)"
        @click="saveConfiguration()"
      >
        {{ $t('save') }}
      </button>
      <button
        slot="button"
        class="btn btn-primary float-right mr-3"
        :disabled="!(selectedConfiguration && selectedConfiguration.id)"
        @click="createConfiguration(true)"
      >
        {{ $t('duplicate') }}
      </button>
      <button
        slot="button"
        class="btn btn-primary float-right mr-3"
        @click="createConfiguration(false)"
      >
        {{ $t('new') }}
      </button>
    </SweetModal>
  </Portlet>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: 'DeviceDetailConfiguration',
  components: {
    DeviceDetailStandardConfiguration: () => import('@/components/Device/DeviceDetail/DeviceDetailStandardConfiguration.vue'),
    DeviceDetailQtConfiguration: () => import('@/components/Device/DeviceDetail/DeviceDetailQtConfiguration.vue'),
    DeviceDetailSmConfiguration: () => import('@/components/Device/DeviceDetail/DeviceDetailSmConfiguration.vue'),
    DeviceDetailUnrolledConfiguration: () => import('@/components/Device/DeviceDetail/DeviceDetailUnrolledConfiguration.vue'),
    DeviceDetailConfigurationEdit: () => import('@/components/Device/DeviceDetail/DeviceDetailConfigurationEdit.vue'),
    SweetModal,
    Multiselect: () => import('vue-multiselect')
  },
  mixins: [
    authenticationMixin,
  ],
  props: {
    uuid: {
      type: String,
      required: true
    },
    configurationId: {
      type: Number,
      required: true
    },
    applicationType: {
      type: String,
      required: true
    },
    device: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      configuration: null,
      unrolledConfiguration: null,
      editConfigurationBoolean: false,
      configurations: [],
      configurationsLoading: false,
      selectedConfiguration: null,
      configurationForEdit: {},
      singleConfigurationsLoading: false
    }
  },
  computed: {
    isSmConfig: function () {
      if (!this.applicationType) {
        return false;
      }
      let type = this.applicationType.toLowerCase();
      if (type === 'sm_start' || type === 'sm_finish') {
        return true;
      }
      return false;
    },
    isQtConfig: function () {
      if (!this.applicationType) {
        return false;
      }
      let type = this.applicationType.toLowerCase();
      if (type === 'terminal-v1' || type === 'terminal-v2' || type.includes('terminal-advt') || type === 'terminal_liveview_photopoint' || type === 'mediastreamer' || type.includes('terminal-photoprint') || type === 'photopoint_liveview') { 
        return true;
      }
      return false;
    },
    restartAppCommandAvailable: function () {
      if(!this.device.vpnIpAddress || !this.device.commands)
      {
        return false;
      }
      else if (this.device.commands.filter(p => p.name == "restartApp").length === 1) {
        return true;
      }
      return false;
    },
    installCommandAvailable: function () {
      if(!this.device.vpnIpAddress || !this.device.commands)
      {
        return false;
      }
      else if (this.device.commands.filter(p => p.name == "execAutostartScript").length === 1) {
        return true;
      }
      return false;
    },
    restartAppCommand: function () {
      if (!this.restartAppCommandAvailable) {
        return new Object();
      }
      else{
        return this.device.commands.filter(p => p.name == "restartApp")[0];
      }
    },
    installCommand: function () {
      if (!this.installCommandAvailable) {
        return new Object();
      }
      else{
        return this.device.commands.filter(p => p.name == "execAutostartScript")[0];
      }
    }
  },
  watch: {
    selectedConfiguration () {
      if(this.selectedConfiguration && this.selectedConfiguration.id) {
        this.getConfig(true);
      }
      else {
        this.configurationForEdit = {};
      }
    }
  },
  created () {
    this.getConfig(false);
  },
  methods: {
    customLabel ({ name }) {
      return `${name}`
    },
    closeModal () {
      this.selectedConfiguration = null;
    },
    resetSelectedConfiguration () {
      this.$refs.configurationsModal.close();
      this.selectedConfiguration = null;
    },
    openConfigurationsModal () {
      this.configurationForEdit = {};
      this.getAllConfigs();
      this.$refs.configurationsModal.open();
    },
    toggleConfiguration () {
      this.editConfigurationBoolean = !this.editConfigurationBoolean;
    },
    createConfiguration (dup) {
      let newConfiguration = {
        name: this.configurationForEdit.name,
        configuration: this.configurationForEdit.configuration,
        applicationUrl: this.configurationForEdit.applicationUrl,
        setAsActive: this.configurationForEdit.setAsActive
      };
      this.axios.post(`/CentralDeviceManagement/CreateDeviceConfiguration?deviceUuid=${ this.uuid }&configurationId=${ this.selectedConfiguration ? this.selectedConfiguration.id : null }&duplicate=${dup}`, newConfiguration)
        .then((response) => {
          if (response && response.data) {
            this.$snotify.success(`Successfully ${(dup ? 'duplicated' : 'created')} configuration!`);
            this.getConfigO(response.data);
          }
          else {
            this.$snotify.error(`Error while ${(dup ? 'duplicating' : 'creating')} configuration!`);
          }
        })
        .catch(() => {
          this.$snotify.error(`Error while ${(dup ? 'duplicating' : 'creating')} configuration!`);
        })
        .finally(() => {
          this.resetSelectedConfiguration();
        });
    },
    saveConfiguration () {
      let newConfiguration = {
        name: this.configurationForEdit.name,
        configuration: this.configurationForEdit.configuration,
        applicationUrl: this.configurationForEdit.applicationUrl,
        setAsActive: this.configurationForEdit.setAsActive
      };
      this.axios.put(`/CentralDeviceManagement/UpdateDeviceConfigurationById?deviceUuid=${ this.uuid }&configurationId=${ this.selectedConfiguration.id }`, newConfiguration)
        .then((response) => {
          if (response && response.data) {
            this.$snotify.success(`Successfully saved configuration!`);
            this.getConfigO(this.selectedConfiguration.id);
          }
          else {
            this.$snotify.error('Error while saving configuration!');
          }
        }).catch(() => {
          this.$snotify.error('Error while saving configuration!');
        })
        .finally(() => {
          this.resetSelectedConfiguration();
        });
    },
    getAllConfigs () {
      this.configurationsLoading = true;
      this.axios.get(`/CentralDeviceManagement/GetAllDeviceConfigurations?deviceUuid=${ this.uuid }`)
        .then((response) => {
          if (!response) {
            return;
          }
          if (!response.data) {
            return;
          }
          this.configurations = response.data;
          this.selectedConfiguration = null;
        })
        .finally(() => {
          this.configurationsLoading = false;
        });
    },
    getConfigO (id) {
      this.loading = true;
      this.axios.get(`/CentralDeviceManagement/GetDeviceConfigurationById?deviceUuid=${ this.uuid }&configurationId=${id}`)
        .then((response) => {
          if (!response) {
            return;
          }
          if (!response.data) {
            return;
          }
          this.configuration = response.data.configuration;
            this.unrolledConfiguration = response.data.unrolledConfiguration;
        })
        .finally(() => {
          this.loading = false
        });
    },
    getConfig (manage) {
      !manage ? (this.loading = true) : (this.singleConfigurationsLoading = true);
      this.axios.get(`/CentralDeviceManagement/GetDeviceConfigurationById?deviceUuid=${ this.uuid }&configurationId=${ manage ? this.selectedConfiguration.id : this.configurationId }`)
        .then((response) => {
          if (!response) {
            return;
          }
          if (!response.data) {
            return;
          }
          if(!manage) {
            this.configuration = response.data.configuration;
            this.unrolledConfiguration = response.data.unrolledConfiguration;
          }
          else {
            this.configurationForEdit = response.data;
          }
        })
        .finally(() => {
          if(!manage) {
            this.editConfigurationBoolean = false;
          }
          !manage ? (this.loading = false) : (this.singleConfigurationsLoading = false);
        });
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>